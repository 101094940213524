import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import assemblageRo from '../../images/other-art/assemblage-ro.png'
import "../layout.css"

import img1 from '../../images/other-art/assemblage/1.jpg'
import img2 from '../../images/other-art/assemblage/2.jpg'
import img3 from '../../images/other-art/assemblage/3.jpg'
import img4 from '../../images/other-art/assemblage/4.jpg'
import img5 from '../../images/other-art/assemblage/5.jpg'
import img6 from '../../images/other-art/assemblage/6.jpg'
import img7 from '../../images/other-art/assemblage/7.jpg'
import img8 from '../../images/other-art/assemblage/8.jpg'
import img9 from '../../images/other-art/assemblage/9.jpg'
import img10 from '../../images/other-art/assemblage/10.jpg'
import img11 from '../../images/other-art/assemblage/11.jpg'
import img12 from '../../images/other-art/assemblage/12.jpg'

export default function Advertistements() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12]
    return (
        <GalleryBackground title="Assemblage" imgSrc={assemblageRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}